import React, { useState, useEffect } from "react";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import classnames from "classnames";
import FsLightbox from "fslightbox-react";

import "../css/overrides/fslightbox.css";

import Layout from "../components/layout";
import SEO from "../components/seo";

const CollectionPage = ({ data }) => {
  const [slide, setSlide] = useState(0);
  const [toggler, setToggler] = useState(false);
  const [ssImages, setSSImages] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const collectionItems = data.prismicCollection.data.body[0].fields.filter(
    f => !!f.image.fluid || !!f.video_embed_iframe_link
  );

  useEffect(() => {
    if (isUpdating) {
      setToggler(!toggler);
      setIsUpdating(false);
    }
  });

  const launchSlideshow = (tag, url) => {
    let validFields;

    if (!!tag) {
      validFields = collectionItems.filter(
        item => item.tag === tag && item.video_embed_iframe_link === null
      );
    } else {
      validFields = collectionItems.filter(
        item => item.video_embed_iframe_link === null
      );
    }

    const newSSImages = validFields.map(item => (
      <div style={{ width: "85vw", height: "90vh" }}>
        <div className="h-full w-full">
          {item.caption && (
            <p
              className="text-sm font-sans absolute text-dark-muted w-full pl-8"
              style={{ marginTop: "-26px" }}
            >
              {item.caption.text}
            </p>
          )}
          <Img
            fluid={item.image.fluid}
            className="h-full max-w-full"
            imgStyle={{ objectFit: "contain" }}
            alt={item.alt_text ? item.alt_text.text : ""}
          />
        </div>
      </div>
    ));
    const newSSURLs = validFields.map(item => item.image.url);
    const ssIndex = newSSURLs.indexOf(url) + 1;

    setSSImages(newSSImages);
    setSlide(ssIndex);
    setIsUpdating(true);
  };

  const {
    collection_name,
    body,
    meta_title,
    meta_description
  } = data.prismicCollection.data;

  const getClassname = image_size => {
    return {
      full: "p-10 md:p-4",
      centered: "p-10",
      right: "p-10 pl-1/10 md:pl-1/10",
      left: "p-10 pr-1/10 md:pr-1/10",
      up: "p-10 pb-1/5 md:pb-1/5",
      down: "p-10 pt-1/5 md:pt-1/5",
      default: "p-10 md:p-4"
    }[image_size];
  };

  return (
    <Layout>
      <SEO description={meta_description} title={meta_title} />
      <div className="flex flex-wrap w-full max-w-6xl mx-auto items-center c-gallery">
        {collectionItems.map(
          (
            {
              caption,
              alt_text,
              image,
              image_size,
              tag,
              video_embed_iframe_link
            },
            index
          ) =>
            video_embed_iframe_link ? (
              <div
                className={classnames(
                  "relative w-full md:w-1/2 self-stretch",
                  getClassname(image_size)
                )}
                key={index}
              >
                <Link
                  to={`/videos/${video_embed_iframe_link.substr(
                    video_embed_iframe_link.lastIndexOf("/") + 1
                  )}`}
                  className="relative block h-full w-full"
                >
                  <div className="absolute h-full w-full z-10" />
                  <iframe
                    className="z-20 w-full h-full"
                    src={`${video_embed_iframe_link}?background=1`}
                  ></iframe>
                </Link>
              </div>
            ) : (
              <div
                key={index}
                className={classnames(
                  "w-full md:w-1/2",
                  getClassname(image_size)
                )}
              >
                <button
                  onClick={() => launchSlideshow(tag, image.url)}
                  className="w-full block focus:outline-none"
                >
                  <Img
                    fluid={image.fluid}
                    className="w-full"
                    alt={image.alt_text ? image.alt_text.text : ""}
                  />
                </button>
              </div>
            )
        )}
      </div>
      <FsLightbox
        toggler={toggler}
        slide={slide}
        key={ssImages}
        customSources={ssImages}
      />
    </Layout>
  );
};

export const query = graphql`
  query CollectionBySlug($uid: String!) {
    prismicCollection(uid: { eq: $uid }) {
      data {
        meta_title
        meta_description
        collection_name
        body {
          ... on PrismicCollectionBodyImageGallery {
            fields: items {
              image {
                alt
                copyright
                url
                fluid(maxWidth: 1200) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
              caption {
                text
              }
              alt_text {
                text
              }
              image_size
              tag
              video_embed_iframe_link
            }
          }
        }
      }
    }
  }
`;

export default CollectionPage;
